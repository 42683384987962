import React, { useEffect, useState } from "react";
import "./style/documents.css";
import { getSignedDocuments, decrypt } from "../functions/function";
import { useNavigate } from "react-router-dom";
import { ServerApi } from "../api/ServerApi";

export default function SignedDocPage() {
  const serverApi = new ServerApi();
  const [userName, setUserName] = useState("");
  const [signedDocs, setSignedDocs] = useState([]);
  const site = localStorage.getItem("site");
  const phone = localStorage.getItem("phone");
  const navigate = useNavigate();
  useEffect(() => {
    const encodedData = localStorage.getItem("esiaDataEncrypt");
    if(!encodedData) navigate('/')
    else {
      const esiaData = JSON.parse(decrypt(encodedData));
      // const esiaData = JSON.parse(localStorage.getItem("esiaData"));
      // Подстановка ФИО в шапку
      if (esiaData) {
        const personInfo = esiaData.personInfo;
        const fullName = `${personInfo.lastName} ${personInfo.firstName} ${personInfo.middleName}`;
        document.querySelector(".header").style.opacity = 1;
        setUserName(fullName);
      }
  
      const user_id = localStorage.getItem("user_id");
      const operator_id = localStorage.getItem("operator_id");
      //Подписанные документы
      getSignedDocuments(operator_id, user_id).then((response) => {
        setSignedDocs(response);
      });
      const keys = ['step_6', 'completion', 'completion_date'];
      serverApi.completeProcess(keys).then((response) => {
        console.log(response)
      })
    }
    
  }, []);

  const handleCall = () => {
    window.location.href = `tel:${phone}`;
  };
  // console.log(signedDocs)
  return (
    <div id="doc-page">
      <div className="fixed-top">
        <div className="header">
          <h1 className="header-title">{userName}</h1>
          <img src="./icons/gos.png" className="logo-gos" />
        </div>
      </div>
        <div className="doc-block">
          <div className="all-doc" style={{ marginBottom: "20px", marginTop:'100px'}}>
            {signedDocs && signedDocs.map((item) => {
              return (
                <div key={item.upload_id}>
                  <div className="signed-item mx-2">
                    <div>
                      <div className="signed-name">{item.document_name}</div>
                      <div className="sinded-container">
                        <div className="signed-date">
                          <a href={item.filePath}>
                            Скачать PDF
                            <i
                              className="fa fa-download"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                        <div className="signed-date">
                          <a href={item.signedFilePath}>
                            Скачать SIG
                            <i
                              className="fa fa-download"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "32px", height: "32px" }}>
                      <img
                        src="./icons/load.svg"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginLeft: "10px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
          {site != '' ? 
          <>
            <a
              href={site}
              target="_blank"
            >
              <div className="signed-item1 mx-2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="./icons/Ruble.svg"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="signed-name">Совершить оплату</div>
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <img
                    src="./icons/Arrow.svg"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            </a>
            <a href={site} target="_blank">
              <div className="signed-item1 mx-2">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src="./icons/Global.svg"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  <div className="signed-name">На сайт оператора</div>
                </div>
                <div style={{ width: "24px", height: "24px" }}>
                  <img
                    src="./icons/Arrow.svg"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </div>
              </div>
            </a>
            </>
            : ''}
            {phone != '' ?
            <div className="signed-item1 mx-2" onClick={handleCall}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="./icons/Call.svg"
                  style={{ width: "20px", height: "20px", marginRight: "10px" }}
                />
                <div className="signed-name">Позвонить оператору</div>
              </div>
              <div style={{ width: "24px", height: "24px" }}>
                <img
                  src="./icons/Arrow.svg"
                  style={{ width: "20px", height: "20px", marginLeft: "10px" }}
                />
              </div>
            </div>
            : ''}
            {/* <div>
                        <div className='mx-3 my-4' style={{display:'flex' , flexDirection:'column' , justifyContent:'center'}}>
                            <p className='doc-subtitle'>Отправить договор на E-mail</p>
                            <input className='input-mail'
                                type="email"
                                placeholder="Введите ваш email"
                                required
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent:'center'}}>
                            <button className='doc-btn'
                                style={{
                                    backgroundColor:'#388DA8',
                                    color: '#fff',
                                    cursor: 'pointer',
                                }}
                                >
                                Продолжить
                            </button>
                        </div>
                    </div> */}
          </div>
        </div>
      </div>
  );
}
